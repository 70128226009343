
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  
  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;
  }

  .language-select {
    margin-left: auto;
    padding-right: 30px;
    cursor: pointer;
  }
  .language-select select {
    padding: 5px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    color:rgb(57,68,96);
  }

  .logo {
    display: flex;
    align-items: center;
    margin-left: 20px; 
  }
  
  .logo img {
    height: 40px;
  }

  .logout-button {
    color: rgb(69, 68, 68);
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    margin-right: 60px;
  }
  
  .chat-footer {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(243,243,243);
    padding: 10px;
    width: 80%;
    font-size: 12px;
    color: #6c757d;
  }
  .chat-vyperf{
    background-color: rgb(46,122,214);
    color: white;
    font-weight: bold;
    padding : 2px;
    margin-left: 2px;
    border-radius: 2px;
  }
/* styles/Chatbot.css */
.conversations-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.conversations-list button {
  margin: 5px 0;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

/* DOTS JUMPING HERE */

.dot-container {
  display: flex;
  gap: 5px; /* Space between dots */
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: jump 0.5s infinite ease-in-out;
}

/* Apply different animation delays and gradients for each dot */
.dot:nth-child(1) {
  animation-delay: 0s;
  background: linear-gradient(to bottom, rgb(9,76,109) 0%, rgb(9,76,109) 100%);
}

.dot:nth-child(2) {
  animation-delay: 0.1s;
  background: linear-gradient(to bottom, rgb(1,185,208) 0%, rgb(1,185,208) 100%);
}

.dot:nth-child(3) {
  animation-delay: 0.2s;
  background: linear-gradient(to bottom, rgb(76,231,177) 0%, rgb(76,231,177) 100%);
}

@keyframes jump {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}