* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.chat-input {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  max-width: min(100%, 46.5rem); 
}

/* Responsive width adjustment for smaller screens */
@media (max-width: 768px) {
  .chat-input {
    width: 95%;
  }
}

/* Avatar */
.avatar {
  width: 30px;
  height: 30px;
}

.messageBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: #1d3b5e0d;
  padding: 0 15px;
  border-radius: 50px;
  border: 1px solid #1d3b5e;
  box-shadow: 2px 0px 16px #cfcacc;
  background: #fafafa;
}

.messageBox:focus-within {
  border: 1px solid #1d3b5e;
}

.fileUploadWrapper label {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.fileUploadWrapper label svg {
  height: 18px;
}
.fileUploadWrapper label svg path {
  transition: all 0.3s;
}

.fileUploadWrapper label:hover svg path {
  stroke: #fff;
}

.fileUploadWrapper label:hover {
  display: block;
  opacity: 1;
}

#messageInput {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0px 20px 0px 10px; /* Adjust right padding to prevent text overlap with button */
  color: #1d3b5e;
}


#sendButton {
  width: fit-content;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}

#sendButton svg {
  height: 18px;
  transition: all 0.3s;
}

#sendButton svg path {
  stroke:#1d3b5e
}

.reset {
  position: relative;
  cursor: pointer;
}

.reset:hover {
  background: #9e9e9e52;
  border-radius: 5px;
}

.reset-tooltip {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 10px;
  background-color: #1d3b5e;
  color: white;
  font-size: 0.75rem;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 1000;
}

.reset:hover .reset-tooltip {
  opacity: 1;
  visibility: visible;
}

/* Style pour le tooltip */
[data-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 10px;
  background-color: #1d3b5e;
  color: white;
  font-size: 0.75rem;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 1000;
  pointer-events: none;
  margin-bottom: 5px;
}

[data-tooltip]:hover:before {
  opacity: 1;
  visibility: visible;
}




