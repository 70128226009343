* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.text{
    white-space: pre-wrap; /* Allows the text to wrap */
    word-wrap: break-word; /* Ensures long words do not break the layout */
    line-height: 1.5;
    font-size: 16px;
}

.streaming-text {
    display: inline;
    opacity: 1;
}

.streaming-text:empty {
    opacity: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.streaming-text span {
    display: inline-block;
    animation: fadeIn 0.1s ease-out forwards;
}

.text strong {
  font-weight: bold;           
  color: #394460;               
}
.text h2, .text h3 {
  font-weight: bold;
  margin: 10px 0;
}

.text h2 {
  font-size: 1.2em;
}

.text h3 {
  font-size: 1.1em;
}


.svg {
  width: 28px;
  margin-bottom: -4px;
}

.chat-window {
    height: 83vh;
    flex: 1;
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px; 
    overflow-y: scroll;
    padding: 25px;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;   
}
@media (min-width: 700px) {
    .chat-window {
        margin-top: 0px !important;  
    }
}

.point {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
  margin-left: 4px;
  animation: dot-animation 1.5s infinite;
}

@keyframes dot-animation {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.point:nth-child(2) {
  animation-delay: 0.5s;
}

.point:nth-child(3) {
  animation-delay: 1s;
}

.chat-window {
    height: 83vh;
    flex: 1;
    margin-top: 0px;
    margin-bottom: 0px; 
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box; 
}
@media (min-width: 700px) {
    .chat-window {
        margin-top: 0px !important;  
    }
}

/* Messages */
.message {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    max-width: 70%;
}

@media (max-width: 600px) {
    .message{
        max-width: 90%;
    }
}

.message.sent {
    align-self: flex-start;
    justify-content: flex-start;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.message.received {
    align-self: flex-end;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

/* Avatar */
.avatar {
    width: 30px;
    height: 30px;
}

/* Message Content */
.message-content {
    position: relative;
    color: #1d3b5e;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.message.sent .message-content::after {
    content: "";
    position: absolute;
    left: -10px;
    top: 0px;
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 10px solid #ACF1D7;
}

.message.sent .message-content .name {
  text-transform: uppercase !important;
  }

.message.received .message-content:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 0px;
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 10px solid #e3f2fd;
}

.message.received .message-content {
    background: #e3f2fd;
    border-radius: 5px 0px 5px 5px;
}
.message.sent .message-content {
    background: #4de6b280;
    border-radius: 0px 5px 5px 5px;
}

.name {
    font-weight: bold;
    color: #394460; 
    font-family: inherit;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 8px;
  align-items: center;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background-color: #90caf9;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  }
  40% { 
    transform: scale(1.0);
  }
}