.chat-container {
  display: flex;
  align-items: top;
  justify-content: center;
  width: 100%;
  height: 91vh;
  padding: 0px;
}

.chat-window-container {
  position: fixed;
  height: auto;
  width: 100%;
  /* max-width: 46.5rem; */
  margin: 0 auto;
  display: flex;
}

@media (max-width: 900px) {
  .chat-container {
    padding: 0;
    width: 92%;
    margin: 0 auto;
  }

  .center-logo-intro {
    width: 100%;
    padding: 0;
  }

  .suggestions-container {
    margin-top: 20px;
    padding: 0px;
    width: 100%;
  }

  .text-adam {
    padding: 0 28px;
    margin: 20px 0;
    width: 100%;
    max-width: none;
  }
}

.-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.center-logo-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center-logo{
  width: 50%;
}
.text-adam{
  color: rgb(57,68,96);
  /* align-items: center;
  justify-content: center; */
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

.suggestions-container {
  margin-top: 40px;
  text-align: center;
}

.suggestions-title {
  font-style: italic;
  margin-bottom: 10px;
}

.suggestions-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.suggestion-button {
  margin: 5px;
  padding: 8px 16px;
  border: 1px solid rgb(1,185,208);
  border-radius: 4px;
  background-color: #e8fff6;
  color: rgb(57,68,96);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(76, 175, 137, 0.1);
}

.suggestion-button:hover {
  background-color: #ACF1D7;
  border-color: #4de6b2;
  color: #1d513d;
  box-shadow: 0 4px 8px rgba(76, 175, 137, 0.2);
}
